import "./team.css";
import React, { Component } from "react";
import Member1 from "../../assets/png/Abhishek Kurian.jpeg";
import Member2 from "../../assets/png/Adithya ajith.jpg";
import Member3 from "../../assets/png/Hanna Salam.jpg";
import Member4 from "../../assets/png/Jaganath e shahi-.jpg";
import Member5 from "../../assets/png/MayonFrancis.png";
import Member6 from "../../assets/png/jaison.jpeg";
import Member7 from "../../assets/png/chriz.jpg";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import Navbar from "../../components/Navbar/Navbar";

export default function Model3() {
  return (
    <div id="model3">
      <Navbar />
      <h1 className="model-title">Team Members</h1>
      <div className="divider"></div>
      <div className="members">
        <div className="member">
          <img width={200} height={200} src={Member1} />
          <div className="description">
            <h1>Abhishek Kurian</h1>
            <h2>Co-Founder</h2>
            <p>
              A Software Developer and a Budding Entrepreneur Shaping the Future
              of Technology."
            </p>
            <div className="social-media">
              <a
                href="https://www.instagram.com/abhishek_kurian_k/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://in.linkedin.com/in/abhishek-kurian"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="member">
          <img width={200} height={200} src={Member2} />
          <div className="description">
            <h1>Adithya Ajith</h1>
            <h2>Co-Founder</h2>
            <p>
              Transforming Ideas into Innovative Solutions: Empowering the
              Digital Future as a Software Developer.
            </p>
            <div className="social-media">
              <a
                href="https://instagram.com/_adxthya?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.linkedin.com/in/adithya-ajith-474545203"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="member">
          <img width={200} height={200} src={Member3} />
          <div className="description">
            <h1>Hanna Salam</h1>
            <h2>Co-Founder</h2>
            <p>
              Building Tomorrow's Technology Today: Empowering Innovation as a
              Software Developer.
            </p>
            <div className="social-media">
              <a
                href="https://www.instagram.com/___.annah____/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://in.linkedin.com/in/hanna-salam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="member">
          <img width={200} height={200} src={Member4} />
          <div className="description">
            <h1>Jaganath E Shahi</h1>
            <h2>Co-Founder</h2>
            <p>
              CodeCrafting a Digital Tomorrow: Empowering Progress as a Software
              Developer
            </p>
            <div className="social-media">
              <a href="" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
              <a
                href="https://in.linkedin.com/in/jagannath-e-shahi-3b0525202"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="member">
          <img width={200} height={200} src={Member5} />
          <div className="description">
            <h1>Mayon Francis</h1>
            <h2>Co-Founder</h2>
            <p>
              Innovating with Lines of Code: Redefining Possibilities as a
              Software Developer.{" "}
            </p>
            <div className="social-media">
              <a
                href="https://linkedin.com/in/mayon-francis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.instagram.com/mayon_francis_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="member">
          <img width={200} height={200} src={Member6} />
          <div className="description">
            <h1>Jaison Dennis</h1>
            <h2> </h2>
            <h2> </h2>
            <h2> </h2>
            <p>
              Unlocking Potential through Code: Empowering the Future as a
              Software Developer.{" "}
            </p>
            <div className="social-media">
              <a
                href="https://www.instagram.com/jaison080"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://linkedin.com/in/jaison080"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="member">
          <img width={200} height={200} src={Member7} />
          <div className="description">
            <h1>Chriz Roy</h1>
            <h2> </h2>
            <h2> </h2>
            <h2> </h2>

            <p>
              Empowering Innovation through Expertly Crafted Code: Building
              Seamless Solutions.{" "}
            </p>
            <div className="social-media">
              <a
                href="https://www.instagram.com/chriz_roy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="www.linkedin.com/in/christopher-roy-a35b2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import Navbar from '../../components/Navbar/Navbar';
import { TypeAnimation } from 'react-type-animation';
import Model1 from '../team/team';




const MainPage = () => {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (
       
   


        <div className='ok'>


            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{


                    background: {
                        image: "url('https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
                        position: 'center',
                        repeat: 'no-repeat',
                        size: 'cover',
                    },



                    fpsLimit: 80,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 120,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: true,
                            opacity: 0.4,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            directions: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 1,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 60,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
                style={{ zIndex: -100 }}
            />

            <Navbar/>
            

            <TypeAnimation
                sequence={[
                    'WELCOME TO', // Types 'One'
                    1000, // Waits 1s
                    'IRIS innovations', // Deletes 'One' and types 'Two'
                    2000, // Waits 2s
                    'build together...', // Types 'Three' without deleting 'Two'
                    () => {
                        console.log('Sequence completed'); // Place optional callbacks anywhere in the array
                    }
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                style={{
                    fontSize: '3rem', color: 'white', display: "flex",
                    fontFamily: "'Rubik Pixels', cursive",

                    alignItems: "center",
                    justifyContent: "center",
                    height: "50rem",
                    zIndex: 99,
                    position: "relative"
                }}
            />
            
            
            
        </div>
    );
};

export default MainPage;
import React from 'react'
import MyComponent from './components/back/back'
import './assets/jpg/image2.jpg'

import { MainPage, About, Team, Products } from './pages'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'

const App = () => {
    

    return (
       
       
   


        <div className='ok'>

        
      
<BrowserRouter>
         <Routes>
           <Route path="/about" element={<About />} />
           <Route path="/" element={<MainPage />} />
           <Route path="contact" element={<Team />} />
           <Route path='/products' element={<Products/>}/>
         </Routes>
     </BrowserRouter>
     

        </div>
    );
};


// import { Profile, MainPage, Events } from './pages'
// import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import Navbar from './components/Navbar/Navbar'
// import Particles from './components/bca/bca'
// import './App.css'
// const App = () => {
//   return (
//     <div className="App">
//     <h1>hi</h1>
//     {/* <Navbar/>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/profile" element={<Profile />} />
//           <Route path="/" element={<MainPage />} />
//           <Route path="/events" element={<Events />} />

//         </Routes>
//       </BrowserRouter> */}

//     </div>
//   )
// }

export default App
import React from 'react'
import myImage from '../../assets/png/logo.png';
import './about.css'
import MyComponent from '../../components/back/back';
import Navbar from '../../components/Navbar/Navbar';
const about = () => {
  return (

    <div class="abt__container">
    <Navbar/>
    
    <div class="us">
        <h1>ABOUT US</h1>
       <body>

       </body>

      
       
    </div>
    
    <div className="flex-container">
      <div className="flex-item"><h1>IRIS Innovations</h1>
      <h3>
   At Iris, our mission is to empower connections and inspire growth. We believe in fostering meaningful relationships and providing a platform where people can connect, share ideas, and collaborate to achieve their dreams. By promoting open communication and mutual support, we aim to create a positive and uplifting environment for all our users.<br></br><br></br>

   Our vision at Iris is to build a global community that thrives together. We envision a world where geographical boundaries are transcended, and diverse voices come together to share knowledge, experiences, and cultures. Through our platform, we aspire to unite individuals from all walks of life, fostering understanding, empathy, and a sense of belonging in a rapidly evolving digital age.
</h3>

      </div>
      <div>
      <img src={myImage} alt="My-Image" className="my-image" />
      </div>
    </div>
    

    </div>
  )
}

export default about
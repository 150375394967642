import React from 'react';
import './back.css'
import { Link } from 'react-router-dom';

function MyComponent() {
  return (
    <div class="mybutton">
      
      <Link to="/">
        <button>HOME</button>
      </Link>
    </div>
  );
}

export default MyComponent;
import React from 'react'
import './Navbar.css'
import navImg from '../../assets/jpg/Logo.jpg'
import Drawer from "@mui/material/Drawer"
import { IoMenu, IoClose } from "react-icons/io5"
import { useState } from 'react'
import { NavHashLink as NavLink } from "react-router-hash-link"


const Navbar = () => {
    const [drawer, setDrawer] = useState(false)
  
    const handleDrawerOpen = () => {
      setDrawer(true)
    }
  
    const handleDrawerClose = () => {
      setDrawer(false)
    }
    return (
        

        <nav class="site-navbar">

        <a href="#home" class="site-logo"><img src={navImg} /></a>

       <div className='desk__contents'>
        <ul>
            <li><a href="/">HOME</a></li>
            <li><a href="/about">ABOUT</a></li>
            <li><a href="/products">PRODUCTS</a></li>
            <li><a href="/contact">CONTACT</a></li>
        </ul>
        </div> 


        <div className="nav_hamburger" onClick={handleDrawerOpen}>
        <IoMenu className="nam_menu_icon" />
      </div>

      <Drawer
      disableScrollLock={true}
      anchor="left"
      open={drawer}
      onClose={handleDrawerClose}
      sx={{
        borderRadius: "20",
        width: "90%",
      }}
    >
    <div className="navbar__mob">
    <div className="navbar_mob_close" onClick={handleDrawerClose}>
      <IoClose className="close_nav_icon"/>
    </div>
    <div className="navbar__mobcontents">
      <NavLink
        to="/"
        onClick={handleDrawerClose}
        className="navmob__link"
      >
        HOME
      </NavLink>
      <NavLink
        to="/about"
        onClick={handleDrawerClose}
        className="navmob__link"
      >
        ABOUT
      </NavLink>
      <NavLink
        to="/products"
        onClick={handleDrawerClose}
        className="navmob__link"
      >
        PRODUCTS
      </NavLink>
     
      <NavLink
        to="/contact"
        onClick={handleDrawerClose}
        className="navmob__link"
      >
        CONTACT
      </NavLink>
      
      
    </div>
  </div>
</Drawer>
    </nav>

    )
}

export default Navbar
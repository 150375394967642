import React from 'react'
import './Products.css'
import rainbow1 from '../../assets/rainbow1.png'
import rainbow2 from '../../assets/png/rain1.png'
import Navbar from '../../components/Navbar/Navbar'
function Products() {
  return (
    /* <div className='products__container'>
    <div className="sp-card-1">
    <ul>
        <li>
            <div className="card-title">Trending Shoes For You</div>
            <div className="sub-line">7 Colors in each Sport Pair </div>
            <span className="off-label">UP TO 65% OFF</span>
            <div className="return-line">15 Days Return and Replacement rykddddddd<br/>ddddddddddddddddddddddddddddddddddddddddd<br/>
            <span style={{ fontSize: '10px' }}>
            Image by{' '}
            <a
              href="https://pixabay.com/users/OpenClipart-Vectors-30363/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=153310"
              style={{ color: '#fff' }}
            >
              OpenClipart-Vectors
            </a>{' '}
            from{' '}
            <a
              href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=153310"
              style={{ color: '#fff' }}
            >
              Pixabay
            </a>
          </span>              
            </div>
        </li>
        <li>
            <div>
            <img alt='' src="https://www.dropbox.com/s/pq93246lri9nb7m/chucks-153310_640.png?raw=1"/></div>
            <div className="price-start">Start From $35</div>                                
        </li>
    </ul>
    <span className="shop-button">
        <a href="#" title="Click for Shop Now">SHOP NOW</a>
    </span> 
</div>        
    </div> */
<div>
<Navbar/>
<div className='products__container'>
<div className="sp-card-1">
  <div className='card__container'>
    <div className='desc__container'>    
    <div className='pro__desc'>
            <div className="card-title">Rainbow</div>
            <div className="sub-line">A Non-Toxic Social Media Platform </div>
            <div className="return-line"><p>App for the collective development of the LGBTQ+ community. This app comprises features like councillor support and booking, which are LGBTQ+ friendly. Revealing their real gender identity can be aided by councillors and practitioners. A facility that notifies about job opportunities and accommodation facilities are available to them for easing the access to a normal life like anyone else by shattering the societal stereotypes against them,  as well as a handle where they can showcase innovative startup ideas which can help them to become more self-reliant.</p>
             
             
        </div>
    </div>
        <div className='sh__btn'>
        <span className="shop-button">
        <a href="https://rainbow.irisinnovation.in/" title="Click for Shop Now">See Product</a>
    </span>
    </div>
    </div>
        <div className='pro__img'>
            <div>
            <img className='pro__images' alt='' src={rainbow2}/>
            </div>
       </div>
    </div>
     
</div>        
    </div>
    </div>
  )
}

export default Products